//
// Prismjs
//

// Highlight
.highlight {
  position: relative;
  background: var(--#{$prefix}prismjs-bg);
  border-radius: $border-radius;
  padding: 1.75rem 1.5rem 1.75rem 1.5rem;

  // Tabs
  .nav {
    border-bottom: 1px solid var(--#{$prefix}prismjs-border);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    margin-top: -0.25rem;

    .nav-item {
      margin-right: 0.75rem;
    }

    .nav-link {
      font-size: 0.9rem;
      font-weight: 500;
      padding: 0.35rem 1rem;
      border-radius: $border-radius;
      color: var(--#{$prefix}prismjs-btn-color);
      transition: $transition-base;
      background-color: transparent;

      &:focus,
      &.active {
        transition: $transition-base;
        background-color: var(--#{$prefix}prismjs-btn-bg-hover);
        color: var(--#{$prefix}prismjs-btn-color-hover);
      }
    }
  }

  .highlight-copy {
    display: none;
    position: absolute;
    right: 1.75rem;
    top: 1.5rem;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0.35rem 1rem !important;
    transition: $transition-base;
    background-color: var(--#{$prefix}prismjs-btn-bg);
    color: var(--#{$prefix}prismjs-btn-color);

    &:focus,
    &:hover {
      transition: $transition-base;
      background-color: var(--#{$prefix}prismjs-btn-bg-hover);
      color: var(--#{$prefix}prismjs-btn-color-hover);
    }
  }

  &:hover {
    .highlight-copy {
      display: flex;
    }
  }

  // Code
  .highlight-code {
    pre {
      background-color: transparent;
      overflow: auto;
      padding: 0;
      margin: 0;

      @include scrollbar-color(
                      var(--#{$prefix}prismjs-scrollbar-color),
                      var(--#{$prefix}prismjs-scrollbar-color-hover)
      );

      code[class*='language-'] {
        padding: 0;
        margin: 0;
        font-size: 1rem !important;
      }
    }
  }
}
